

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';




const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;






const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;


const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;










const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;



const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function HairLossfaq() {
    return (
        <Section py="4" id="faq" bg="#f7f7fb" className="pb-md-5 mb pt-md-5">
      <Container className="pb-md-5 mb pt-md-5">
        <Row className="justify-content-center">
          <Col lg="12">
   
            <Iwrap>


<Itext as="h2">Questions about Nose Job</Itext>
</Iwrap>
      <SecondText>
      Frequently Asked Questions

      </SecondText>
         
          </Col>
        </Row>


        <Row className="">
       
        <Col
     
     >
    <Accordion allowZeroExpanded classname="">
  
  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              What is Nose Job?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              A non-surgical nose job, also known as a liquid rhinoplasty or nose filler procedure, is a cosmetic treatment that involves using injectable fillers to reshape and enhance the appearance of the nose without surgery.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              How does the non surgical nose job differ from traditional nose job?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              Unlike traditional nose job, which requires surgical incisions and alterations to the nose structure, a non-surgical nose job is performed using dermal fillers injected into specific areas of the nose to achieve desired results without surgery.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              What are the benefits of a non-surgical nose job?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              Some of the key benefits of a non-surgical nose job include immediate results, minimal downtime, reduced risk compared to surgery, and the ability to make subtle adjustments to the nose's appearance.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              Is a non-surgical nose job painful?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              Most patients report minimal discomfort during the nose job. Topical numbing cream or local anesthesia may be used to minimize any sensations during the procedure.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              How long does a non-surgical nose job take?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              The nose job typically takes around 10 to 15 minutes to complete, making it a quick and convenient option for those with busy schedules.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              Are the results of a non-surgical nose job permanent?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              The results of this kind of nose job are not permanent. However, they can last anywhere from 9 to 18 months, depending on factors such as the type of filler used and individual metabolism.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              What can I expect during recovery after a non-surgical nose job?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              There is minimal downtime associated with this type of nose job, and most patients can return to their normal activities immediately after the procedure. Some minor swelling or bruising may occur but typically resolves within a few days.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              Who is a good candidate for a non-surgical nose job?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              Good candidates for this type of nose job are individuals who desire minor changes to the shape or symmetry of their nose and prefer a non-invasive alternative to surgery. They should be in good overall health with realistic expectations for the outcome of the procedure.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              Can a non-surgical nose job correct breathing issues?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              This type of nose job is primarily focused on enhancing the aesthetic appearance of the nose and is not intended to address functional issues such as breathing difficulties. In cases where breathing issues are present, a consultation with an otolaryngologist or plastic surgeon may be necessary to explore appropriate treatment options.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
  <br />

  <AccordionItem>
      <AccordionItemHeading>
          <AccordionItemButton>
              How much does a non-surgical nose job cost?
          </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
          <p>
              Dermamina offers this type of nose job for £450 per session.
          </p> 
      </AccordionItemPanel>
  </AccordionItem>
</Accordion>



          </Col>




        </Row>



<br /> <br />
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment
                
                
                </Bookbutton> </a>
        


       


        </Container>
        </Section>

 


    );
}

