import React from "react";
import Link from 'gatsby-link';
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import { Parallax, Background } from 'react-parallax';
import imgMobile from "../../assets/image/jpeg/liquid.jpg";
import Recovery from "../../sections/rhino/rhinore.js";
import icon3dModel from "../../assets/image/png/icon-3d-model.png";
import iconLayout from "../../assets/image/png/icon-layot-25.png";
import SectionOne from '../../sections/countersection/countersection';
import Newarrow from '../../assets/image/jpeg/enter.svg'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;






const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;


const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: rgb(171, 142, 102);
font-size: 14px;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 12px;
}

`;



const SecondText = styled.h3`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;
      font-smooth: always;
      margin-bottom: 0.4em;
     
      color: #1a1a1a;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 24px;
          text-align:center;
      }

`;


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 0px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const HairContentPage = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section py={4}>
      <Container className="pt-lg-5 pb-lg-5">

          <Row>
              <Col>
                <Iwrap>


<Itext as="h2">Nose Job Treatment London</Itext>
</Iwrap>
      <SecondText>
      What is nose job treatment in London?

      </SecondText>
              </Col>
          </Row>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4 pl-0 pr-0 ml-0 ml-0 mb-lg-0">
            <div
              className="pl-0"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <ImgStyled src={imgMobile} alt="PRP Hair Loss Tratment" className="img-fluid" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className=" pt-lg-0">
          
              <Text>

              At Dermamina, we offer non-surgical nose job solutions in London. Our innovative approach to nose enhancement, often referred to as the "10-minute nose job," offers a quick, safe, and effective alternative to traditional nose jobs. With our expert practitioners and advanced techniques, you can achieve the nose of your dreams without the need for invasive surgery. <br /> <br />

              Our nose job treatment, also known as a liquid rhinoplasty or nose filler procedure, involves the strategic injection of hyaluronic acid-based dermal fillers into specific areas of the nose. These fillers are carefully placed to address concerns such as a dorsal hump, nasal asymmetry, or a drooping nasal tip, resulting in immediate improvements to the nose's appearance. <br /> <br />

              At Dermamina, we specialize in the "10-minute nose job" technique, offering our clients a quick and convenient solution to nose enhancement. During your appointment, our skilled practitioners will assess your unique facial anatomy and aesthetic goals before administering the dermal fillers. The entire procedure typically takes just 10 minutes, allowing you to return to your daily activities with minimal downtime.

           





              </Text> <br />
            
              <a href="https://connect.pabau.com/bookings.php?compid=8607" activeClassName="active"><Bookbutton>Book Appointment
                </Bookbutton> </a>
           
            </Box>
          </Col>
        </Row>

       
               
      </Container>
    </Section>
  </>
);

export default HairContentPage;
